@mixin small {
	@media screen and (min-width: #{$small}) {
		@content;
	}
}

@mixin medium {
	@media screen and (min-width: #{$medium}) {
		@content;
	}
}

@mixin large {
	@media screen and (min-width: #{$large}) {
		@content;
	}
}

@mixin xlarge {
	@media screen and (min-width: #{$xLarge}) {
		@content;
	}
}

@mixin xxlarge {
	@media screen and (min-width: #{$xxLarge}) {
		@content;
	}
}