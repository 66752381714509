$almostBlack: #121415;
$darkGrey: #575557;
$mediumGrey: #939597;
$lightGrey: #f5f5f5;
$white: #ffffff;
$yellow: #f5bd47;

$bgColor: $lightGrey;
$headingColor: $darkGrey;
$bodyCopyColor: $darkGrey;
$primaryFont: "Inter", sans-serif;
$secondaryFont: "Fira Code", sans-serif;
$fontSize: 62.5%;

// Breakpoints
$small: 576px;
$medium: 768px;
$large: 992px;
$xLarge: 1400px;
$xxLarge: 1920px;
