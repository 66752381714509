@import "variables";
@import "media-queries";

/* Table of Content
==================================================
	- google fonts & font family
    - utilities
	- general
    - links
    - images
    - typography
    - preloader
	- header
    - box intro section
    - portfoilo section
    - footer
    - about page
    - services page
    - contact page
    - portfolio single page
    - responsive media queries
*/


/* google fonts & font family
==================================================*/

// Fira Code Regular 400 (latin)
@font-face {
    font-family: 'Fira Code';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/firacode/v21/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_D1sJVD7NuzlojwUKQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Inter  Regular 400 (latin)
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Inter SemiBold 600 (latin)
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Inter Bold 700 (latin)
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYAZ9hiJ-Ek-_EeA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

// Inter Black 900 (latin)
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

/* utilities
==================================================*/

.lighter-text {
    color: $mediumGrey;
}

// padding especial
.py-e {
    padding-top: 5.6rem;
    padding-bottom: 9.6rem;
    @include medium {
        padding-top: 8rem;
        padding-bottom: 24rem;
    }
}

/* general
==================================================*/

html {
    font-size: $fontSize;
}

*,
*:after,
*:before {
	margin: 0;
    padding: 0;
}

body {
    background-color: $bgColor;
    color: $bodyCopyColor;
    font-family: $primaryFont;
    font-size: 16px; /* 1rem = 10px */
    line-height: 1.8;
}

.plus {
    display: flex;
    justify-content: center;
    padding-top: 1.6rem;
    &__icon {
        font-size: 3.2rem;
        &:link,
        &:visited {
            color: #888;
        }
        &:focus,
        &:hover,
        &:active {
            color: $yellow;
        }
    }
}

/* links
==================================================*/

a {
    &:link,
    &:visited {
        color: $darkGrey;
        font-weight: 600;
        transition: all .3s;
    }
    &:focus,
    &:hover,
    &:active {
        color: $yellow;
    }
    &[href='#']{
        pointer-events: none;
        cursor: default;
        color: #cdcdcd;
    }
}

// a {
// 	&:link,
// 	&:visited {
// 		color: $darkGrey;
// 		transition: color 0.3s;
// 		text-decoration: none;
// 	}
// 	&:hover {
// 		color: $mediumGrey;
// 	}
// }


/* images
================================================== */

/* Para asegurarse de que las imágenes utilizadas se comporten con fluidez: */
img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
}


/* typography
==================================================*/

h1, h2, h3 {
    color: $darkGrey;
    font-weight: 600;
}

h1 {
    font-size: 5.6rem;
    // text-transform: uppercase;
}

h2 {
    font-size: 3.2rem;
}

h3 {
    font-size: 2.2rem;
}

p {
    font-size: 1.8rem;
    b,
    strong {
        font-weight: 600;
    }
}

.fira-font {
    font-family: $secondaryFont;
}



/* preloader
==================================================*/

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $lightGrey;
    /* change if the mask should be a color other than white */
    z-index: 1000;
    /* makes sure it stays on top */
}

.pre-container {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $yellow;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1.0s;
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
    }
}


/* header
==================================================*/

.header {
    align-items: center;
    backface-visibility: hidden;
    background: transparent;
    display: flex;
    height: 8rem;
    justify-content: space-between;
    left: 0;
    mix-blend-mode: multiply;
    padding: 0 1.6rem;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: background-color 0.3s;
    width: 100%;
    z-index: 3;
    &.is-fixed {
        position: fixed;
        top: -8rem;
        // background-color: rgba(255, 255, 255, 0.96);
        transition: transform 0.3s;
    }
    &.is-visible {
        transform: translate3d(0, 100%, 0);
    }
    &__logo {
        display: block;
        // padding: 3.2rem;
        a:link,
        a:visited {
            color: $darkGrey;
            transition: color 0.3s;
        	text-decoration: none;
            text-transform: uppercase;
            font-size: 2rem;
            line-height: 2rem;
            font-family: $primaryFont;
            @include medium {
                font-size: 3.2rem;
                line-height: 3.2rem;
            }
        }
        a:hover,
        a:active {
            color: $darkGrey;
        }
        &__name {
            font-weight: 700;
        }
        &__surname {
            font-weight: 400;
        }
    }
    &.menu-is-open {
        // background-color: rgba(255, 255, 255, 0.96);
        background: transparent;
        mix-blend-mode: normal;
        .header__logo {
            a {
                color: $white;
            }
        }
    }
    @include medium {
        padding: 0 2.4rem;
    }
}

.nav-trigger {
    height: 30px;
    line-height: 30px;
    display: block;
    position: relative;
    .box-menu-text {
        color: $darkGrey;
        display: none;
        font-family: $secondaryFont;
        font-size: 2.4rem;
        font-weight: 400;
        padding-right: 4.8rem;
        transition: color 0.3s;
        &.is-clicked {
            color: $white;
        }
        @include small {
            display: inline-block;
        }
    }
    .box-menu-icon {
        background-color: rgba($color: $darkGrey, $alpha: 1);
        bottom: auto;
        display: inline-block;
        height: .2rem;
        left: auto;
        position: absolute;
        right: 1.2rem;
        top: 50%;
        transform: translateX(0) translateY(-50%);
        transition: background-color 0.3s;
        width: 2.4rem;
        &::before,
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: $darkGrey;
            right: 0;
            transition: transform .3s, top .3s, background-color 0s;
        }
        &::before {
            top: -8px;
        }
        
        &::after {
            top: 8px;
        }
        &.is-clicked {
            background-color: rgba($color: $darkGrey, $alpha: 0);
            &::before,
            &::after {
                background-color: $white;
            }
            &::before {
                top: 0;
                transform: rotate(135deg);
            }
            
            &::after {
                top: 0;
                transform: rotate(225deg);
            }
        }
    }
}

.nav {
    align-items: center;
    backface-visibility: hidden;
    background: rgba(0, 0, 0, 0.96);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition-duration: 0.4s;
    transition-property: transform;
    width: 100%;
    z-index: 2;
    &.is-visible {
        transform: translateY(0);
    }
    &__links,
    &__contact,
    &__social {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    &__links,
    &__contact {
        margin-bottom: 4rem;
        li {
            margin: 0.2rem 0;
            a {
                &:link,
                &:visited {
                    color: $white;
                    display: inline-block;
                    font-family: $secondaryFont;
                    font-size: 1.8rem;
                    font-weight: 400;
                    padding: 0.4rem;
                    text-decoration: none;
                    transition: all 0.3s;
                    @include small {
                        font-size: 2.4rem;
                    }
                }
                &:focus,
                &:hover,
                &:active {
                    outline: none;
                    color: $yellow;
                    text-decoration: underline;
                }
            }
        }
    }
    &__links {
        li {
            &:first-child {
                margin: 0.4rem 0 4rem;
            }
        }
    }
    &__label {
        color: $yellow;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.6rem;
        letter-spacing: 0.4rem;
        margin: 2.4rem 0 0.8rem;
    }
    &__social {
        &__icon {
            display: inline-block;
            margin: 1.2rem;
            a {
                &:link,
                &:visited {
                    color: $white;
                    display: block;
                    width: 2.4rem;
                    height: 2.4rem;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                }
                &:focus,
                &:hover,
                &:active {
                    color: $yellow;
                }
            }
        }
    }
}


/* Hero
==================================================*/

.hero {
    text-align: center;
    display: table;
    height: 56vh;
    width: 100%;
    position: relative;
    .table-cell {
        display: table-cell;
        vertical-align: middle;
    }
    em {
        font-style: normal;
        text-transform: uppercase;
    }
    .sub-heading {
        color: $darkGrey;
        font-family: $secondaryFont;
        font-size: 1.6rem;
        // letter-spacing: .4rem;
        // text-transform: uppercase;
        padding: 0 1.6rem;
        @include medium {
            font-size: 2.4rem;
        }
    }
    @include small {
        height: 72vh;
    }
    @include medium {
        height: 100vh;
    }
}

/* text rotate */

.hero-headline {
    font-size: 4.8rem;
    font-weight: 900;
    line-height: 1.0;
    .box-words-wrapper {
        display: inline-block;
        position: relative;
        b {
            display: inline-block;
            position: absolute;
            white-space: nowrap;
            &.is-visible {
                position: relative;
            }
            &.followers {
                left: 50%;
                top: 0;
                transform: translateX(-50%);
            }
            i:last-child {
                color: $yellow;
            }
        }
    }
    @include small {
        font-size: 7.2rem;
    }
    @include medium {
        font-size: 8.8rem;
    }
    @include large {
        font-size: 12rem;
    }
    @include xlarge {
        font-size: 16rem;
    }
    @include xxlarge {
        font-size: 24rem;
    }
}

.no-js .box-words-wrapper b {
    opacity: 0;
}

.no-js .box-words-wrapper b.is-visible {
    opacity: 1;
}

.hero-headline.rotate-2 .box-words-wrapper {
    perspective: 300px;
}

.hero-headline.rotate-2 i,
.hero-headline.rotate-2 em {
    display: inline-block;
    backface-visibility: hidden;
}

.hero-headline.rotate-2 b {
    opacity: 0;
}

.hero-headline.rotate-2 i {
    transform-style: preserve-3d;
    transform: translateZ(-20px) rotateX(90deg);
    opacity: 0;
}

.is-visible .hero-headline.rotate-2 i {
    opacity: 1;
}

.hero-headline.rotate-2 i.in {
    animation: box-rotate-2-in 0.4s forwards;
}

.hero-headline.rotate-2 i.out {
    animation: box-rotate-2-out 0.4s forwards;
}

.hero-headline.rotate-2 em {
    transform: translateZ(20px);
}

.no-csstransitions .hero-headline.rotate-2 i {
    transform: rotateX(0deg);
    opacity: 0;
}

.no-csstransitions .hero-headline.rotate-2 i em {
    transform: scale(1);
}

.no-csstransitions .hero-headline.rotate-2 .is-visible i {
    opacity: 1;
}

@keyframes box-rotate-2-in {
    0% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(90deg);
    }
    60% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(-10deg);
    }
    100% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(0deg);
    }
}

@keyframes box-rotate-2-out {
    0% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(0);
    }
    60% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(-100deg);
    }
    100% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(-90deg);
    }
}

/* mouse effect */
.mouse {
    position: absolute;
    width: 22px;
    height: 42px;
    bottom: 40px;
    left: 50%;
    margin-left: -12px;
    border-radius: 15px;
    border: 2px solid #888;
    animation: intro 1s;
}

.scroll {
    display: block;
    width: 3px;
    height: 3px;
    margin: 6px auto;
    border-radius: 4px;
    background: #888;
    animation: finger 2s infinite;
}

@keyframes intro {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes finger {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}


/* portfolio section
==================================================*/
.portfolio {
    &__nav {
        padding: 4rem 0;
        ul {
            margin: 0;
            li {
                display: inline-block;
                a {
                    &:link,
                    &:visited {
                        color: $darkGrey;
                        font-family: $secondaryFont;
                        margin: 0 8px;
                        padding: 0 8px;
                    }
                    &:focus,
                    &:hover,
                    &:active {
                        text-decoration: none;
                        color: $yellow;
                    }
                    &.active {
                        color: $yellow;
                    }
                }
            }
        }
    }
}

.portfolio_item {
    position: relative;
    overflow: hidden;
    display: block;
    .portfolio_item_hover {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, .8);
        // transform: translate(-100%);
        opacity: 0;
        transition: all .2s ease-in-out;
        .item_info {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 10px;
            width: 100%;
            font-weight: bold;
            span {
                display: block;
                color: $white;
                font-weight: 600;
                font-size: 2rem;
                transform: translateX(-100px);
                transition: all .2s ease-in-out .2s;
                opacity: 0;
            }
            em {
                color: $white;
                display: inline-block;
                font-family: $secondaryFont;
                font-style: normal;
                margin-top: 10px;
                opacity: 0;
                transform: translateX(-100px);
                transition: all .3s ease-in-out .3s;
            }
        }
    }
    &:hover {
        .portfolio_item_hover {
            opacity: 1;
            // transform: translateX(0);
        }
        .item_info em,
        .item_info span {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

// .portfolio .categories-grid .categories ul li {
//     float: left;
// }


/* footer
==================================================*/
footer {
    padding-top: 3rem;
    p {
        line-height: 1.2;
        &.location {
            text-align: left;
            @include medium {
                text-align: right;
            }
        }
    }
}


/* backto top
==================================================*/
.cd-top {
    background: rgba($color: $yellow, $alpha: .8);
    bottom: 20px;
    display: inline-block;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    opacity: 0;
    position: fixed;
    right: 10px;
    text-align: center;
    transition: opacity .3s 0s, visibility 0s .3s;
    visibility: hidden;
    width: 40px;
    i.bi-arrow-up {
        color: #fff;
    }
}

.cd-top.cd-is-visible,
.cd-top.cd-fade-out,
.no-touch .cd-top:hover {
    transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
    /* the button becomes visible */
    visibility: visible;
    opacity: 1;
}

.cd-top.cd-fade-out {
    opacity: .5;
}

.no-touch .cd-top:hover,
.no-touch .cd-top:focus {
    background-color: $yellow;
    opacity: 1;
    color: #fff;
}


/* about page
==================================================*/

.top-bar {
    padding: 24rem 0 4rem;
    background: $white;
    h1 {
        font-size: 5.6rem;
        font-weight: 600;
        font-family: $secondaryFont;
        line-height: 1.2;
        b {
            font-weight: 900;
        }
        @include small {
            font-size: 8rem;
        }
        @include medium {
            font-size: 12rem;
        }
    }
    .breadcrumb {
        font-size: 1.6rem;
        font-family: $secondaryFont;
        color: $mediumGrey;
        a {
            &:link,
            &:visited {
                color: $mediumGrey;
                display: inline;
                margin: 0 8px;
            }
            &:focus,
            &:hover,
            &:active {
                color: $yellow;
            }
        }
    }
}

.social {
    li {
        a {
            font-size: 2.4rem;
        }
    }
}

.role,
.course {
    margin-bottom: 4rem;
}

/* services page
==================================================*/

.size-50 {
    font-size: 50px;
}

.service-box {
    margin-bottom: 30px;
}

.service-box h3 {
    margin-top: 0;
}


/* contact page
==================================================*/

.details-text i {
    margin-right: 10px;
}

.textarea-contact {
    height: 200px;
    width: 100%;
    border: solid 1px rgba(0, 0, 0, .1);
    position: relative;
}

.textarea-contact textarea {
    height: 100%;
    width: 100%;
    border: 0;
    padding: 20px;
    background-color: transparent;
    float: left;
    z-index: 2;
    font-size: 14px;
    color: #9a9a9a;
    resize: none;
}

.textarea-contact > span {
    position: absolute;
    top: 20px;
    left: 20px;
    transform: translateY(-50%);
    font-size: 12px;
    text-transform: uppercase;
    color: #cdcdcd;
    transition: all .2s ease-in-out;
    z-index: 1;
}

.input-contact {
    height: 40px;
    width: 100%;
    border: solid 1px rgba(0, 0, 0, .1);
    position: relative;
    margin-bottom: 30px;
}

.input-contact input[type="text"] {
    height: 100%;
    width: 100%;
    border: 0;
    padding: 0 20px;
    float: left;
    position: relative;
    background-color: transparent;
    z-index: 2;
    font-size: 14px;
    color: #9a9a9a;
}

.input-contact > span {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 12px;
    text-transform: uppercase;
    color: #cdcdcd;
    transition: all .2s ease-in-out;
    z-index: 1;
}

.input-contact > span.active,
.textarea-contact > span.active {
    color: $yellow;
    font-size: 10px;
    top: 0px;
    left: 5px;
    background-color: #fff;
    padding: 5px
}

input:focus,
textarea:focus {
    outline: none;
}

.contact-info {
    margin-top: 20px;
}

.btn-box {
    background: $yellow;
    padding: 10px 50px;
    border-radius: 0;
    color: #fff;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: .4rem;
    font-weight: 500;
}

.btn-box:hover,
.btn-box:focus {
    background: #F5B700;
    color: #fff;
}


/* single project page
==================================================*/

.cat-ul {
    padding-left: 0;
    list-style: none;
    font-family: $secondaryFont;
}

.prev-next{
    &__btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__all {
            font-size: 3.2rem;
            &:link,
            &:visited {
                color: #888;
            }
            &:focus,
            &:hover,
            &:active {
                color: $yellow;
            }
        }
    }
}

/* responsive media queries
==================================================*/

// @media only screen and (max-width: 670px) {
//     .nav a {
//         padding: 5px 1em;
//         font-size: 14px;
//     }
//     .nav {
//         padding: 80px 0 0;
//     }
//     .top-bar h1 {
//         font-size: 40px;
//         line-height: 30px;
//     }
//     .portfolio .categories-grid .categories ul li {
//         float: none;
//     }
// }

// @media only screen and (max-width: 420px) {
//     .top-bar h1 {
//         font-size: 30px;
//         line-height: 30px;
//     }
// }